export default {
  "finance": {
    "cost_record": "Charges record",
    "bill_number": "Bill serial number",
    "money": "Amount",
    "account_balance": "Account balance",
    "balance": "Balance",
    "bill": "Bill",
    "download_bill_details": "Download bill details",
    "print_bill": "Print bill",
    "immediate_payment": "Pay now",
    "overdraft_limit": "Overdraw credit",
    "expiration_time": "Due time",
    "overdraft_status": "Overdrawing status",
    "increase_limit": "Upgrade credit",
    "transaction_serial_number": "Transaction serial number",
    "relevant_business_number": "Relevant business number",
    "facility_code": "Facility code",
    "balance_transaction_details": "Transaction details of balance",
    "recharge_serial_number": "Recharging serial number",
    "recharge_currency": "Recharging currency",
    "recharge_amount": "Recharging amount",
    "payment_currency": "Payment currency",
    "payment_method": "Payment Method",
    "exchange_rate": "Exchange rate",
    "payment_amount": "Payment amount",
    "payment_serial_number": "Payment serial number",
    "recharge_status": "Recharging status",
    "remarks": "Remarks",
    "business_no": "Business number",
    "business_time": "Business time",
    "transaction_type": "Transaction type",
    "business_type": "Business type",
    "business_country": "Business country",
    "happen_currency": "Currency of occurrence",
    "affiliation_order": "Attributed bill",
    "fee_detail": "Charges details",
    "serial_numbers": "Serial number",
    "serial_number": "Sequence number",
    "business_belonging_cycle": "Business attribution cycle",
    "create_time": "Creating time ",
    "details": "Details",
    "to": "To",
    "month_bill": "Bill of month",
    "account_number": "Account number",
    "title_of_account": "Account name",
    "bank_name": "Bank name",
    "error_and_again": "Error, please try again.",
    "please_select_payment_currency_first": "Please select payment currency first",
    "freeze": "Freeze",
    "create_recharge": "创建充值",
    "view_storage_fee_details": "查看仓储费明细",
    "export_storage_fee_details": "导出仓储费明细"
  }
}